<template>
    <div>
        <div v-for="group in row.item.groups">{{ group }}</div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>